import { Link } from "react-router-dom";
import logo from "../../../images/logos/logo.png";

export function Logo() {
  return (
    <Link to="/" aria-label="Home" className="text-2xl flex items-center">
      <img src={logo} alt="Stonk Donkey Logo" className="h-12 w-auto mr-2" />
      Stonk Donkey
    </Link>
  );
}
