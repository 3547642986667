import { Container } from "../components/Container";
import backgroundImage from "../../../images/background-call-to-action.jpg";
import { useForm, ValidationError } from "@formspree/react";

export function CallToAction() {
  const [state, handleSubmit] = useForm("xvgprojj");
  if (state.succeeded) {
    return <p>Thanks for joining!</p>;
  }

  return (
    <section
      id="join-waitlist"
      className="relative overflow-hidden bg-blue-600 py-32"
    >
      <img
        className="absolute left-1/2 top-1/2 max-w-none -translate-x-1/2 -translate-y-1/2"
        src={backgroundImage}
        alt=""
        width={2347}
        height={1244}
      />
      <Container className="relative">
        <div className="mx-auto max-w-lg text-center">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
            Join the Waitlist
          </h2>
          <p className="mt-4 text-lg tracking-tight text-white">
            Be the first to know when Stonk Donkey launches. Sign up for
            exclusive early access.
          </p>
          <form onSubmit={handleSubmit} className="mt-10">
            <div className="flex items-center justify-center">
              <input
                id="email"
                type="email"
                name="email"
                placeholder="Enter your email"
                className="px-4 py-2 rounded-l-md w-64 focus:outline-none"
                required
              />
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
              <button
                type="submit"
                disabled={state.submitting}
                className="bg-white text-blue-600 px-4 py-2 rounded-r-md font-semibold hover:bg-blue-100 transition duration-300"
              >
                Join
              </button>
            </div>
          </form>
        </div>
      </Container>
    </section>
  );
}
