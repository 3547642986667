import { Outlet } from "react-router-dom";
import "@fontsource/inter";
import "@fontsource/lexend";

export default function RootLayout() {
  return (
    <div className="h-full scroll-smooth bg-white antialiased font-inter">
      <div className="flex h-full flex-col">
        <Outlet />
      </div>
    </div>
  );
}
