import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./landing";
import Register from "./(auth)/register/page";
import Login from "./(auth)/login/page";
import RootLayout from "./layout";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<RootLayout />}>
          <Route index element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
